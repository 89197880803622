<template>
    <tr>
        <td>{{ new Date(order.created_at).toLocaleString() }}</td>

        <td>{{ order.id }}</td>

        <td>{{ order.store.legal_name }}</td>

        <td>
            <span v-if="order.store.legal_name !== 'Gefeli'">{{ $t('card') }}: {{ order.card_title }}</span>
            <span v-if="order.store.legal_name === 'Gefeli'">
                {{ $t('card') }}: {{ order.detail.product_slug }}
                <br/>
                Envelop: {{ order.detail.variant_slug }}
                <br/>
                OrderId: {{ order.reference }}
            </span>
            <br>

            <span v-if="order.gift_title">{{ $t('gift') }}: {{ order.gift_title }}</span>
        </td>

        <td>{{ $t('order_statuses.' + order.status) }}</td>

        <td>
            {{ order.addressee.reference }} {{ order.addressee.first_name }}
            {{ (order.addressee.prefix ? order.addressee.prefix + ' ' : '') + order.addressee.last_name }}
            <br/>
            {{ order.addressee.street }} {{ order.addressee.house_nr }} {{ order.addressee.addition ?? '' }}
            <br/>
            {{ order.addressee.zip }} {{ order.addressee.city }}
            <br/>
        </td>

        <td>
            <span v-if="order.detail.message !== ''">
                {{ $t('card_text') }}:
                <br/>

                <span style="display: block; max-width: 300px; white-space: pre-wrap">
                    {{ order.detail.message }}
                </span>
            </span>
        </td>

        <td>
            <slot name="statusButtons"></slot>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'HipOrderLine',

    props: {
        order: Object,
    },
}
</script>
