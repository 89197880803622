<template>
    <div>
        <div class="mt-2 text-gray-500">
            <hip-orders-table
                :orders="orders"
                :total-orders="totalOrders"
                :per_page="per_page"
                :order-buttons="[3]"
            />
        </div>
    </div>
</template>
<script>
import HipOrdersTable from "./HipOrdersTable";

export default {
    name: 'HipOrdersRetour',

    components: {
        HipOrdersTable
    },

    data() {
        return {
            orders: {},
            totalOrders: 0,
            page: 1,
            per_page: 10,
        }
    },

    props: {
        filter_from: String,
        filter_till: String,
        filter_shop: {
            type: Number,
            default: 0
        },
        filter_ordernr: String,
        filter_addressee: String,
    },

    created() {
        this.getOrders()
    },

    watch: {
        filter_from() {
            this.getOrders()
        },
        filter_till() {
            this.getOrders()
        },
        filter_shop() {
            this.getOrders()
        },
        filter_ordernr() {
            this.getOrders()
        },
        filter_addressee() {
            this.getOrders()
        },
    },

    methods: {
        getOrders(page) {
            if (page) {
                this.page = page
            }

            axios.get('/weboffice/orders-hip/data', {
                params: {
                    from: this.filter_from,
                    till: this.filter_till,
                    shop: this.filter_shop,
                    status: 1,
                    ordernr: this.filter_ordernr,
                    addressee: this.filter_addressee,
                    page: this.page,
                    per_page: this.per_page
                }
            })
                .then(response => {
                    this.orders = response.data.orders
                    this.totalOrders = response.data.total
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>
