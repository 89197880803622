<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th>{{ $t('hip.order_date') }}</th>
                <th>{{ $t('hip.order_nr') }}</th>
                <th>{{ $t('hip.shop') }}</th>
                <th>{{ $t('hip.product_title') }}</th>
                <th>{{ $t('hip.status') }}</th>
                <th>{{ $t('hip.customer_details') }}</th>
                <th>{{ $t('hip.delivery') }}</th>
                <th>{{ $t('hip.change_status') }}</th>
            </tr>
            </thead>
            <tbody>
            <slot name="textRow"></slot>

            <HipOrderLine
                v-for="order in orders"
                :key="order.id"
                :order="order"
            >
                <template #statusButtons>
                    <button @click="changeStatus(order, 1)"
                            v-if="order.status !== 1 && orderButtons.includes(1)"
                            class="mb-8 btn-text-color text-white align-middle items-center px-4 py-3 bg-success w-100 border border-transparent rounded-md font-semibold text-xs uppercase tracking-widest active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition">
                        {{ $t("hip.confirm_order_send") }}
                    </button>

                    <button @click="changeStatus(order, 3)"
                            v-if="order.status !== 3 && order.status !== 0 && orderButtons.includes(3)"
                            class="mb-8 btn-text-color bg-warning w-100 text-white align-middle items-center px-4 py-3 bg-gray-800 border border-transparent rounded-md font-semibold text-xs uppercase tracking-widest active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition">
                        {{ $t("hip.order_returned") }}
                    </button>

                    <button @click="changeStatus(order, 2)"
                            v-if="order.status !== 2 && orderButtons.includes(2)"
                            class="mb-8 btn-text-color text-white align-middle items-center px-4 py-3 bg-danger w-100 border border-transparent rounded-md font-semibold text-xs uppercase tracking-widest active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition">
                        {{ $t("hip.order_unorderable") }}
                    </button>

                    <button @click="changeStatus(order, 0)"
                            v-if="order.status !== 0"
                            class="btn-text-color text-white align-middle items-center px-4 py-3 bg-gray-400 w-100 border border-transparent rounded-md font-semibold text-xs uppercase tracking-widest active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition">
                        {{ $t("hip.order_processing") }}
                    </button>
                </template>
            </HipOrderLine>

            </tbody>
        </table>

        <div class="grid place-items-center text-center">
            <pagination
                v-model="page"
                :records="totalOrders"
                :per-page="per_page"
                @paginate="changePage"
                :options="{
                    texts: {
                        count: 'Toon {from} tot {to} van {count} orders|{count} orders|Eén order',
                    },
                }"
            />
        </div>
    </div>
</template>

<script>
import HipOrderLine from "./HipOrderLine";
import Pagination from 'v-pagination-3';

export default {
    name: 'HipOrdersTable',

    components: {
        HipOrderLine,
        Pagination
    },

    props: {
        orders: {},
        totalOrders: Number,
        per_page: Number,
        orderButtons: Array,
    },

    data() {
        return {
            page: 1,
        }
    },

    methods: {
        changeStatus(order, status) {
            axios.post('/weboffice/order/product/' + order.id + '/update', {
                status: status,
            })
                .then(response => {
                    this.$parent.getOrders()
                })
                .catch(error => {
                    console.log(error)
                })
        },
        changePage(page) {
            this.$parent.getOrders(page)
        }
    }
}
</script>
