<template>
    <div>
        <div class="pt-8 pb-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="pr-5 pl-5 pt-2 pb-4 shadow-xl overflow-hidden sm:rounded bg-white">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="from">{{ $t('filter_from') }}</label>

                                    <input type="date" v-model="filter_from" id="from" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="till">{{ $t('filter_till') }}</label>

                                    <input type="date" v-model="filter_till" id="till" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="shop">{{ $t('filter_shop') }}</label>

                                    <select v-model="filter_shop" id="shop" class="form-select">
                                        <option value="" v-if="tabName !== 'hip-orders'">
                                            {{ $t('filter_shop_all') }}
                                        </option>

                                        <option v-for="shop in shops" :value="shop.id">{{ shop.legal_name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="shop">{{ $t('filter_addressee') }}</label>

                                    <input type="text" v-model="filter_addressee" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="shop">{{ $t('filter_ordernr') }}</label>

                                    <input type="text" v-model="filter_ordernr" class="form-control">
                                </div>
                            </div>

                            <div class="col-md-4" v-if="tabName === 'hip-orders-search'">
                                <div class="form-group">
                                    <label for="shop">{{ $t('filter_status') }}</label>

                                    <select v-model="filter_status" id="status" class="form-select">
                                        <option value=""></option>

                                        <option v-for="status in statuses" :value="status">
                                            {{ $t('order_statuses.' + status) }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="py-6">
            <!-- activity of the store -->
            <div class="row">
                <div class="col-md-12">
                    <div class="pr-5 pl-5 pt-2 pb-2 shadow-xl overflow-hidden sm:rounded bg-white">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#hip-orders"
                                   @click="changeTab('hip-orders')">{{ $t('hip.orders_overview') }}</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#hip-orders-retour"
                                   @click="changeTab('hip-orders-retour')">{{ $t('hip.orders_retour') }}</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" data-bs-target="#hip-orders-search"
                                   @click="changeTab('hip-orders-search')">{{ $t('hip.orders_search') }}</a>
                            </li>
                        </ul>

                        <div class="tab-content">
                            <div id="hip-orders" class="tab-pane fade show active">
                                <HipOrders
                                    :filter_from="filter_from"
                                    :filter_till="filter_till"
                                    :filter_shop="filter_shop"
                                    :filter_ordernr="filter_ordernr"
                                    :filter_addressee="filter_addressee"
                                    v-if="tabName === 'hip-orders'"
                                />
                            </div>

                            <div id="hip-orders-retour" class="tab-pane fade">
                                <HipOrdersRetour
                                    :filter_from="filter_from"
                                    :filter_till="filter_till"
                                    :filter_shop="filter_shop"
                                    :filter_ordernr="filter_ordernr"
                                    :filter_addressee="filter_addressee"
                                    v-if="tabName === 'hip-orders-retour'"
                                />
                            </div>

                            <div id="hip-orders-search" class="tab-pane fade">
                                <HipOrdersSearch
                                    :filter_from="filter_from"
                                    :filter_till="filter_till"
                                    :filter_shop="filter_shop"
                                    :filter_status="filter_status"
                                    :filter_ordernr="filter_ordernr"
                                    :filter_addressee="filter_addressee"
                                    v-if="tabName === 'hip-orders-search'"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HipOrders from './HipOrders.vue';
import HipOrdersRetour from "./HipOrdersRetour.vue";
import HipOrdersSearch from "./HipOrdersSearch.vue";

export default {
    name: 'HipOverview.vue',

    components: {
        HipOrders,
        HipOrdersRetour,
        HipOrdersSearch
    },

    data() {
        return {
            shops: [],
            statuses: [],
            filter_from: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
            filter_till: null,
            filter_shop: null,
            filter_status: null,
            filter_ordernr: null,
            filter_addressee: null,
            tabName: 'hip-orders'
        }
    },

    methods: {
        changeTab(tab) {
            this.tabName = tab;
        },
        getShops() {
            axios.get('/weboffice/orders-hip/shops')
                .then(response => {
                    this.shops = response.data;
                })
                .catch(error => {
                    toastr.error('Winkels kunnen niet worden opgehaald');
                });
        },
        getStatuses() {
            axios.get('/weboffice/orders-hip/statuses')
                .then(response => {
                    this.statuses = response.data;
                })
                .catch(error => {
                    toastr.error('Statussen kunnen niet worden opgehaald');
                });
        }
    },

    mounted() {
        this.getShops();
        this.getStatuses();
    }
}
</script>
