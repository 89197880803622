__webpack_nonce__ = window.__webpack_nonce__;

require('./bootstrap');
window.toastr = require('toastr');
import { Chart, registerables } from 'chart.js';
import Sortable from 'sortablejs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
window.Chart = Chart;
window.ChartDataLabels = ChartDataLabels;
window.Sortable = Sortable;

let lang = window.Laravel.lang;

$(function () {
    window.datatables_language = {
        'url': `/lang/datatables_${lang}.json`
    };
})

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { Fancybox } from "@fancyapps/ui";
//import tinymce
import tinymce from 'tinymce/tinymce';

window.tinyMCE = tinymce;
import 'tinymce/models/dom/model'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';
import { TempusDominus } from '@eonasdan/tempus-dominus';
import 'slick-carousel';

window.TempusDominus = TempusDominus;

function loadLocaleMessages() {
    const locales = require.context("../lang", true, /[A-Za-z0-9\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const i18n = new createI18n({
    locale: lang,
    fallbackLocale: "nl",
    allowComposition: true,
    messages: loadLocaleMessages(),
    silentTranslationWarn: process.env.NODE_ENV === "development"
});

import HipOverview from './components/HIP/HipOverview'

const app = createApp(HipOverview)
    .use(i18n)

app.mount('#hipoverview')

$(function () {
    Fancybox.bind("[data-fancybox]", {
        compact: false,
        idle: false,

        animated: false,
        showClass: false,
        hideClass: false,

        dragToClose: false,
        contentClick: false,

        Images: {
            // Disable animation from/to thumbnail on start/close
            zoom: false,
        },

        Toolbar: {
            display: {
                left: [],
                middle: ['infobar'],
                right: ['close'],
            },
        },

        Thumbs: {
            type: 'classic',
        },
    });

    $(document).ready(function () {
        if ($('.carousel').length) {
            $('.carousel .item-wrapper')
                .on('init', function () {
                    $(this).css('opacity', 1);
                    $('.slick-navigation').css('opacity', 1);
                })
                .slick({
                    appendArrows: $('.slick-navigation'),
                });
        }
    });
})
